import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  @Input() imagensPath: Array<string>;

  imagens: Array<string>;

  constructor() { }

  ngOnInit() {
    this.imagens = this.imagensPath;
  }

}
