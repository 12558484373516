import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './site/home/home.component';
import { CadastroComponent } from './site/cadastro/cadastro.component';
import { ShopComponent } from './site/shop/shop.component';
import { DownloadComponent } from './site/download/download.component';
import { AjudaComponent } from './site/ajuda/ajuda.component';
import { NotFoundComponent } from './site/not-found/not-found.component';
import { RankLevelComponent } from './site/rank-level/rank-level.component';
import { RankOnlineComponent } from './site/rank-online/rank-online.component';
import { LoginComponent } from './site/login/login.component';
import { PasswordResetComponent } from './site/password-reset/password-reset.component';
import { MeuPainelComponent } from './site/meu-painel/meu-painel.component';
import { AuthGuard } from './service/security/auth.guard';
import { RequestResetComponent } from './site/request-reset/request-reset.component';
import { TutorialComponent } from './site/tutorial/tutorial.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'home/:t', component: HomeComponent },
  { path: 'cadastro', component: CadastroComponent },
  { path: 'loja', component: ShopComponent },
  { path: 'downloads', component: DownloadComponent },
  { path: 'ticket', component: AjudaComponent },
  { path: 'ticket/:token', component: AjudaComponent },
   { path: 'rank-level', component: RankLevelComponent },
   { path: 'rank-online', component: RankOnlineComponent },
  { path: 'reset-senha', component: PasswordResetComponent, canActivate: [AuthGuard]},
  { path: 'reset-senha/:guide', component: PasswordResetComponent},
  { path: 'solicitar-reset', component: RequestResetComponent},
  { path: 'meu-painel', component: MeuPainelComponent },
  { path: 'tutorial', component: TutorialComponent },
  { path: 'login', component: LoginComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
