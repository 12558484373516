import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material';

@Component({
  selector: 'app-logged-menu',
  templateUrl: './logged-menu.component.html',
  styleUrls: ['./logged-menu.component.scss']
})
export class LoggedMenuComponent implements OnInit {

  @Input() loginTrigger?: MatMenuTrigger;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
  }
  public logout() {
    this.authService.logout();
  }
  redirectTo(link: string, target?: string) {
    if (target) {
      link += "/" + target;
    }
    this.router.navigate([link]).then(result => {
      this.loginTrigger.closeMenu();
    });
  }
}
