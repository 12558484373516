import { Component, OnInit, ViewChild } from '@angular/core';
import { ReCaptcha2Component } from 'ngx-captcha';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { RequestResetModel } from './model/request-reset.model';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountService } from 'src/app/service/account.service';
import { AuthService } from 'src/app/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.scss']
})
export class RequestResetComponent implements OnInit {
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;

  emailFormControl: FormControl;
  recaptchaFormControl: FormControl;
  siteKey = Constants.recaptcha_key;

  form: FormGroup;

  requestReset: RequestResetModel;

  constructor(private accountService: AccountService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.requestReset = new RequestResetModel();
    if(this.authService.logged){
      this.redirectTo("reset-senha");
    }
    this.bindFormControl();
  }
  sendForm() {

  }
  handleReset() {
    this.recaptchaFormControl.reset();
  }
  handleLoad() { }
  handleExpire() {
    this.recaptchaFormControl.reset();
  }
  handleSuccess($event) {
    this.requestReset.tokenRobot = $event;
  }
  sendReset() {
    if (!this.form.valid) {
      return;
    }
    this.requestReset.email = this.emailFormControl.value;

    this.form.disable();
    this.accountService.requestReset(this.requestReset).subscribe(
      result => {
        if (result.success) {
          this.sendResetSuccess();
        }
        else {
          this.sendResetErrorCustom(result.errors);
        }
      },
      error => {
        this.sendResetError(error);
      });
  }
  redirectTo(link: string, target?: string) {
    if (target) {
      link += "/" + target;
    }
    this.router.navigate([link]);
  }
  private sendResetSuccess() {
    this.form.enable();
    this.captchaElem.resetCaptcha();
    Swal.fire("Tudo certo!", "Link para reset de senha enviada para o e-mail da conta.", "success");
    this.bindFormControl();
  }
  private sendResetError(response: HttpErrorResponse) {
    this.form.enable();
    this.captchaElem.resetCaptcha();
    if (response.error && response.error.errors) {
      Swal.fire("Ops...", response.error.errors[0], "warning");
    }
    else {
      Swal.fire("Ops...", "Ocorreu um erro ao solicitar reset de senha da sua conta. Se o problema persistir, contate o administrador via grupo do telegram: https://t.me/+HpheQxFVT1pmYzNh", "error");
    }
  }
  private sendResetErrorCustom(error: string[]) {
    Swal.fire("Ops...", "Ocorreu um erro ao solicitar reset de senha da sua conta. Se o problema persistir, contate o administrador via grupo do telegram: https://t.me/+HpheQxFVT1pmYzNh", "error");
  }
  private bindFormControl() {

    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")
    ]);
    this.recaptchaFormControl = new FormControl('', [
      Validators.required
    ]);

    this.form = new FormGroup({
      emailFormControl: this.emailFormControl,
      recaptchaFormControl: this.recaptchaFormControl,
    })
  }
}
