import { Component, OnInit } from '@angular/core';
import { HeaderCache } from 'src/app/menu/header/model/header-cache';
import { CharBasicInfoModel } from 'src/app/shared/model/character/char-basic-info-model';
import { CharacterService } from 'src/app/service/character.service';

@Component({
  selector: 'app-rank-online',
  templateUrl: './rank-online.component.html',
  styleUrls: ['./rank-online.component.scss']
})
export class RankOnlineComponent implements OnInit {

  topOnlineOnlineCache: HeaderCache<Array<CharBasicInfoModel>>;
  topOnline: Array<CharBasicInfoModel>;

  constructor(public characterService: CharacterService) { }

  ngOnInit() {
    this.topOnlineOnlineCache = JSON.parse(localStorage.getItem("top100lOnlineCache"));
    if(!this.topOnlineOnlineCache || this.varifyDateExpired(this.topOnlineOnlineCache.date)){
      this.topOnlineOnlineCache = new HeaderCache<Array<CharBasicInfoModel>>();
      this.buscartopOnline();
    }
    else {
      this.topOnline = this.topOnlineOnlineCache.data;
    }
  }
  buscartopOnline() {
    this.characterService.topPlayTime(100).subscribe(
      result => {
        this.findtopOnlineSuccess(result.data);
      },
      error => {
        console.error(error)
      });
  }
  public returnPlayTimeFormated(playTime: number): string {
    if(!playTime){
      return;
    }
    let hours = (playTime / 60);
    let minutes = (playTime % 60);
    return  (Math.round(hours) < 10 ? '0'+hours.toFixed(0): hours.toFixed(0))+':' + (Math.round(minutes) < 10 ? '0'+minutes.toFixed(0): minutes.toFixed(0)) + ' h';
  }
  private findtopOnlineSuccess(data: Array<CharBasicInfoModel>) {
    this.topOnline = data;
    while(this.topOnline.length < 10){
      let player = new CharBasicInfoModel();
      player.name = " **************** ";
      this.topOnline.push(player);
    }
    this.topOnlineOnlineCache.data = this.topOnline;
    localStorage.setItem("top100lOnlineCache", JSON.stringify(this.topOnlineOnlineCache));
  }
  private varifyDateExpired(date: Date) : boolean {
    var dateNow = new Date();
    var dateParam = new Date(date);
    return (dateParam.getTime() + (1000 * 60 * 0.5)) < dateNow.getTime();
  }

}
