import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { CadastroComponent } from './cadastro/cadastro.component';
import { MenuModule } from '../menu/menu.module';
import { UiModule } from '../ui/ui.module';
import { ShopComponent } from './shop/shop.component';
import { FormsModule } from '@angular/forms';
import { DownloadComponent } from './download/download.component';
import { AjudaComponent } from './ajuda/ajuda.component';
import { EquipeComponent } from './equipe/equipe.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NoticesComponent } from './notices/notices.component';
import { RankLevelComponent } from './rank-level/rank-level.component';
import { RankOnlineComponent } from './rank-online/rank-online.component';
import { RouterModule } from '@angular/router';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { MeuPainelComponent } from './meu-painel/meu-painel.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { RequestResetComponent } from './request-reset/request-reset.component';
import { TutorialComponent } from './tutorial/tutorial.component';

@NgModule({
  declarations: [HomeComponent, CadastroComponent, ShopComponent, DownloadComponent, AjudaComponent, EquipeComponent, NotFoundComponent, NoticesComponent, RankLevelComponent, RankOnlineComponent, PasswordResetComponent, MeuPainelComponent, RequestResetComponent, TutorialComponent],
  imports: [
    CommonModule,
    FormsModule,
    MenuModule,
    UiModule,
    RouterModule,
    NgxCaptchaModule
  ],
  exports:[HomeComponent, CadastroComponent]
})
export class SiteModule { }
