import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cbm-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit   {

  @Input() largura: number;
  @Input() texto: string;

  stylesObj = {};
  fonteStyle = {};

  ngOnInit(): void {
    this.stylesObj = {
      width: this.largura
    }
    this.fonteStyle = {
      "font-size" : this.largura / this.texto.length
    }
    console.log(this.stylesObj);
  }
  

}
