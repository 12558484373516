import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    baseUrl = "api/Download";

    constructor(public http: HttpClient, public authService : AuthService) { }

    public downloadLastVersion() {
        this.http.get(this.baseUrl, { headers: this.authService.getHeaderWithToken().headers, responseType: 'blob' })
        .subscribe(data => this.saveFile(data, "CBMix.exe")),
            error => console.log('Error downloading the file.', error),
            () => console.info('OK');
    }

    private saveFile(blob, filename) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0)
    }
}