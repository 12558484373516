import { NgModule } from '@angular/core';
import { MatMenuModule, MatButtonModule, MatCardModule, MatInputModule, MatDividerModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatTableModule, MatPaginatorModule, MatExpansionModule, MatBottomSheetModule, MatProgressBarModule } from '@angular/material';

const modules = [
  MatCardModule,
  MatMenuModule,
  MatButtonModule,
  MatInputModule,
  MatDividerModule,
  MatIconModule,
  MatFormFieldModule,
  MatTableModule,
  MatPaginatorModule,
  MatExpansionModule,
  MatBottomSheetModule,
  MatSelectModule,
  MatProgressBarModule,

];

@NgModule({
  declarations: [],
  imports: modules,
  exports: modules
})
export class MaterialModule { }
