import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { PasswordResetModel } from './model/password-reset.model';
import { AccountService } from 'src/app/service/account.service';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  resetSenhaModel : PasswordResetModel;

  senhaAtualFormControl: FormControl;
  novaSenhaFormControl: FormControl;
  novaSenha2FormControl: FormControl;

  form: FormGroup;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private accountService :AccountService) { }

  ngOnInit() {

    this.resetSenhaModel = new PasswordResetModel();

    this.route.paramMap.subscribe(params => {
      if(params.get("guide")){
        this.valideteResetLink(params.get("guide"));
      }
      else {
        this.resetSenhaModel.link = undefined;
      }
    });
    this.bindFormControl();
  }
  valideteResetLink(link: string) {
    
    this.resetSenhaModel.link = link;
    this.form.disable();
    this.accountService.validateResetLink(link).subscribe(
      result => {
        this.form.enable();
      }, 
      error => { 
        console.error(error), 
        this.requestFail(error);
        this.redirectTo("solicitar-reset");
    });
  }
  public redirectTo(link: string, target?: string) {
    if (target) {
      link += "/" + target;
    }
    this.router.navigate([link]);
  }
  public enviar() {

    if(!this.form.valid){
      return;
    }
    
    this.bindModel();
    this.form.disable();

    if(!this.resetSenhaModel.link || this.resetSenhaModel.link.length == 0) {
      this.accountService.passwordReset(this.resetSenhaModel).subscribe(
        () => {
          this.requestSuccess();
      }, 
        error => { 
          console.error(error), 
          this.requestFail(error);
      });
    } 
    else  {
      this.accountService.passwordResetByLink(this.resetSenhaModel).subscribe(
        () => {
          this.requestSuccess();
      }, 
        error => { 
          console.error(error), 
          this.requestFail(error);
      });
    }
  }
  public logout(){
    this.authService.logout();
  }
  private requestSuccess(){
    Swal.fire("Tudo certo!", "Sua senha foi resetada com sucesso.", "success");
    this.form.enable();
  }
  private requestFail(data: HttpErrorResponse){
    if(data.status == 400 || data.status == 401){
      Swal.fire("Atenção!", (data.error && data.error.errors ? data.error.errors[0] : "Dados inválidos. Se o problema persistir, contate o ADM do sistema."), "warning");
    }
    else {
      Swal.fire("Ops...", "Ocorreu um erro no reset de senha. Se o problema persistir, contate o ADM do sistema.", "error");
    }
    this.form.enable();
  } 
  private bindModel(){
    this.resetSenhaModel.senhaAtual = this.senhaAtualFormControl.value;
    this.resetSenhaModel.novaSenha = this.novaSenhaFormControl.value;
    this.resetSenhaModel.novaSenha2 = this.novaSenha2FormControl.value;

  }
  private checkSenha(senhaCompare: FormControl): ValidatorFn {
    return (c: AbstractControl): {} => {
      if(!c || !senhaCompare){
        return;
      }
      var valor1 = (senhaCompare ? senhaCompare.value : "");
      var valor2 = (c ? c.value : "");
      if (valor1 === valor2) {
        return null;
      }
      return { incorrect: true };
    };
  }
  private bindFormControl() {
  
    var senhaAtualValidators = new Array<ValidatorFn>();
    if(!this.resetSenhaModel.link || this.resetSenhaModel.link.length == 0){
      senhaAtualValidators.push(Validators.required);
      senhaAtualValidators.push(Validators.minLength(8));
    }

    this.senhaAtualFormControl = new FormControl('', senhaAtualValidators);
    
    this.novaSenhaFormControl = new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      this.checkSenha(this.novaSenha2FormControl)
    ]);
    this.novaSenha2FormControl = new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      this.checkSenha(this.novaSenhaFormControl)
    ]);

    this.form = new FormGroup({
      senhaAtualFormControl: this.senhaAtualFormControl,
      novaSenhaFormControl: this.novaSenhaFormControl,
      novaSenha2FormControl: this.novaSenha2FormControl,
    });
  }
}
