import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { DownloadService } from 'src/app/service/download.service';
import { Constants } from 'src/app/shared/constants';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  links = Constants.urlsDownload;
  constructor(private downloadService:DownloadService, public authService : AuthService) { }

  ngOnInit() {
  }
  download() {
    if(!this.authService.logged){
      Swal.fire("Ops...", "Faça login para continuar com o download", "info");
      return;
    }
    this.downloadService.downloadLastVersion();
  }
}
