import { Component, OnInit, ViewChild } from '@angular/core';
import { CadastroModel } from './model/cadastro.model';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/service/account.service';
import Swal from 'sweetalert2';
import { IbgeService } from 'src/app/service/ibge.service';
import { Estado } from 'src/app/shared/model/region/estado.model';
import { Cidade } from 'src/app/shared/model/region/cidade.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';
import { Constants } from 'src/app/shared/constants';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;

  cadastro = new CadastroModel();

  idJogadorFormControl: FormControl;
  senhaFormControl: FormControl;
  nomeFormControl: FormControl;
  sobrenomeFormControl: FormControl;
  estadoFormControl: FormControl;
  cidadeFormControl: FormControl;
  perguntaSecretaFormControl: FormControl;
  respostaPerguntaSecretaFormControl: FormControl;
  respostaEnqueteFormControl: FormControl;
  emailFormControl: FormControl;
  recaptchaFormControl: FormControl;
  siteKey = Constants.recaptcha_key;

  visible: boolean;
  validRegion: boolean = true;
  validStates: boolean = true;

  form: FormGroup;

  estados: Array<Estado>;
  cidades: Array<Cidade>;

  constructor(private accountService: AccountService, private ibgeService: IbgeService, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    if (this.authService.logged) {
      Swal.fire("Ops...", "Aparentemente você já está logado, caso queira fazer um novo cadastro, favor deslogar antes.", "warning");
      this.redirectTo("home");
    }
    this.findEstados();
    this.bindFormControl();
  }
  handleReset() {
    this.recaptchaFormControl.reset();
  }
  handleLoad() { }
  handleExpire() {
    this.recaptchaFormControl.reset();
  }
  handleSuccess($event) {
    this.cadastro.tokenRobot = $event;
  }
  findEstados() {
    try {
      this.ibgeService.getEstados().subscribe(
        result => {
          this.estados = result.sort(function (a, b) { return a.sigla > b.sigla ? 1 : -1 });
        },
        error => {
          this.invalidStates();
        });
    }
    catch {
      this.invalidStates();
    }
  }

  private invalidStates() {
    this.validRegion = false;
    this.validStates = false;
  }

  changeEstado() {
    try {
      this.ibgeService.getCidades(this.estadoFormControl.value).subscribe(
        result => {
          this.cidades = result.sort(function (a, b) { return a.nome > b.nome ? 1 : -1 });
        },
        error => {
          this.validStates = false;
        });
    }
    catch {
      this.validStates = false;
    }
  }

  sendRegister() {
    if (!this.form.valid) {
      Swal.fire("Ops...", "Aparantemente existem campos obrigatórios sem preencher, caso tenha usado algum preenchimento automático como de e-mail por exemplo, tente inserir a informação manualmente", "info");
      this.bindMessagesModel();
      return;
    }
    else {
      this.bindModel();
      this.form.disable();
      this.accountService.register(this.cadastro).subscribe(
        result => {
          this.sendRegisterSuccess();
        },
        error => {
          this.sendRegisterError(error);
        });
    }
  }
  redirectTo(link: string, target?: string) {
    if (target) {
      link += "/" + target;
    }
    this.router.navigate([link]);
  }
  private sendRegisterSuccess() {
    this.form.enable();
    Swal.fire("Conta criada com sucesso!", "Seu id de jogador é: #" + this.cadastro.idJogador + ".", "success");
    this.bindFormControl();
  }
  private sendRegisterError(response: HttpErrorResponse) {
    this.form.enable();
    if (response.error && response.error.errors) {
      Swal.fire("Ops...", response.error.errors[0], "warning");
    }
    else {
      Swal.fire("Ops...", "Ocorreu um erro ao criar sua conta. Se o problema persistir, contate o administrador via grupo do telegram: https://t.me/+HpheQxFVT1pmYzNh", "error");
    }
  }
  private sendRegisterErrorCustom(error: string[]) {
    Swal.fire("Ops...", "Ocorreu um erro ao criar sua conta. Se o problema persistir, contate o administrador via grupo do telegram: https://t.me/+HpheQxFVT1pmYzNh", "error");
  }
  private erroServico() {
    Swal.fire("Ops...", "Ocorreu um erro ao consultar o serviço de regiões. Se o problema persistir, contate o administrador via grupo do telegram: https://t.me/+HpheQxFVT1pmYzNh", "error");
  }
  private bindModel() {
    this.cadastro.idJogador = this.idJogadorFormControl.value;
    this.cadastro.senha = this.senhaFormControl.value;
    this.cadastro.nome = this.nomeFormControl.value;
    this.cadastro.sobrenome = this.sobrenomeFormControl.value;
    this.cadastro.estado = this.validRegion ? this.estados.find(x => x.id === this.estadoFormControl.value).sigla : 'Serviço indisponível';
    this.cadastro.cidade = this.validStates ? this.cidades.find(x => x.id === this.cidadeFormControl.value).nome : 'Serviço indisponível';
    this.cadastro.perguntaSecreta = this.perguntaSecretaFormControl.value;
    this.cadastro.respostaPerguntaSecreta = this.respostaPerguntaSecretaFormControl.value;
    this.cadastro.respostaEnquete = this.respostaEnqueteFormControl.value;
    this.cadastro.email = this.emailFormControl.value;

  }
  private bindMessagesModel() {
    this.idJogadorFormControl.markAsTouched();
    this.senhaFormControl.markAsTouched();
    this.nomeFormControl.markAsTouched();
    this.sobrenomeFormControl.markAsTouched();
    this.estadoFormControl.markAsTouched();
    this.cidadeFormControl.markAsTouched();
    this.perguntaSecretaFormControl.markAsTouched();
    this.respostaPerguntaSecretaFormControl.markAsTouched();
    this.respostaEnqueteFormControl.markAsTouched();
    this.emailFormControl.markAsTouched();
  }
  public revalidateEmail() {
    this.emailFormControl.updateValueAndValidity();
  }
  private emailMatcher = (control: AbstractControl): { [key: string]: boolean } => {
    const email = control.get('email');
    const confirm = control.get('confirm');
    if (!email || !confirm) return null;
    if (email.value === confirm.value) {
      return null;
    }
  };

  private TestValidRegion = (control: AbstractControl): { [key: string]: boolean } => {
    if (!this.validRegion) return null;
  }

  private TestValidState = (control: AbstractControl): { [key: string]: boolean } => {
    if (!this.validStates) return null;
  }

  private bindFormControl() {

    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}")
    ]);

    this.idJogadorFormControl = new FormControl('', [
      Validators.required,
      Validators.minLength(5),
      Validators.pattern("^[A-Za-z][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$")
    ]);

    this.senhaFormControl = new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]);
    this.nomeFormControl = new FormControl('', [
      Validators.required
    ]); this.sobrenomeFormControl = new FormControl('', [
      Validators.required
    ]);
    this.estadoFormControl = new FormControl('', [
      this.TestValidRegion
    ]);
    this.cidadeFormControl = new FormControl('', [
      this.TestValidState
    ]);
    this.perguntaSecretaFormControl = new FormControl('', [
      Validators.required
    ]);
    this.respostaPerguntaSecretaFormControl = new FormControl('', [
      Validators.required
    ]);
    this.respostaEnqueteFormControl = new FormControl('', [
      Validators.required
    ]);
    this.recaptchaFormControl = new FormControl('', [
      Validators.required
    ]);
    this.form = new FormGroup({
      emailFormControl: this.emailFormControl,
      idJogadorFormControl: this.idJogadorFormControl,
      senhaFormControl: this.senhaFormControl,
      nomeFormControl: this.nomeFormControl,
      sobrenomeFormControl: this.sobrenomeFormControl,
      estadoFormControl: this.estadoFormControl,
      cidadeFormControl: this.cidadeFormControl,
      perguntaSecretaFormControl: this.perguntaSecretaFormControl,
      respostaPerguntaSecretaFormControl: this.respostaPerguntaSecretaFormControl,
      respostaEnqueteFormControl: this.respostaEnqueteFormControl,
      recaptchaFormControl: this.recaptchaFormControl
    });
  }
}
