export class CadastroModel {
    idJogador: string;
    senha: string;
    nome: string;
    sobrenome: string;
    email: string;
    estado: string;
    cidade: string;
    perguntaSecreta: string;
    respostaPerguntaSecreta: string;
    respostaEnquete: string;
    tokenRobot: string;
}