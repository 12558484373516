import { Injectable } from '@angular/core';
import { Estado } from '../shared/model/region/estado.model';
import { GenericResponseArray } from '../shared/model/base/generic-response';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cidade } from '../shared/model/region/cidade.model';

@Injectable({
  providedIn: 'root'
})
export class IbgeService {

  endpointEstados = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";
  endpointCidades = "https://servicodados.ibge.gov.br/api/v1/localidades/estados/{UF}/municipios";

  constructor(public http: HttpClient) { }

  public getEstados(): Observable<Estado[]> {
    var url = this.endpointEstados;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<Estado[]>(url);
  }
  public getCidades(idEstado: number): Observable<Cidade[]> {
    var url = this.endpointCidades.replace("{UF}", idEstado.toString());
    return this.http.get<Cidade[]>(url);
  }
}
