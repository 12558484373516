import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService} from '../auth.service';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
  })
export class AuthGuard implements CanActivate {

    constructor(private AuthService: AuthService, private router: Router, private ActivatedRoute: ActivatedRoute){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | import("@angular/router").UrlTree | Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
        return this.AuthService.verifyUserLogged().pipe(map(e => {
            if (e.success) {
                return true;
            }
            else {
                this.router.navigate(["login"], { queryParams: { returnUrl: state.url }}).then(() => {
                    Swal.fire("Ops...", "Você precisa fazer login para continuar.", "warning");
                });
                return false;
            }
               
        }),catchError((e: Response) => throwError(this.errorReturn(e, state)))
        );
    }
    private errorReturn(e: Response, state: RouterStateSnapshot) : any {
        if(e.status == 401){
            Swal.fire("Ops...", "Você precisa fazer login para continuar.", "warning");
        }
        else if(e.status == 504){
            Swal.fire("Ops... :(", "Serviço está indisponível no momento. Tente novamente mais tarde.", "error");
        }
        else {
            Swal.fire("Erro ao verificar sessão! :(", "Não foi possível verificar a sessão do usuário. Contate o Administrador do sistema.", "error");
            console.log("error: ", e);
        }
        this.router.navigate(["login"], { queryParams: { returnUrl: state.url }});
        
        return e;
    }
}