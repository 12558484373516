import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { LoginModel } from '../site/login/model/login.model';
import { LoginInfoModel } from '../site/login/model/login-info.model';
import { GenericResponse } from '../shared/model/base/generic-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public url: string;
  get logged() : boolean {
    return this.userActive != undefined;
  };
  public userActive: LoginInfoModel;

  constructor(private http: HttpClient, private router: Router) {
    this.url = "/api/Login";
  }
  public logout() {
    localStorage.removeItem('currentUserCBM');
    this.userActive = undefined;
  }
  public setLoginCache(userLogin: LoginModel) {
    localStorage.setItem('currentUserCBM', JSON.stringify(userLogin));
    this.getActiveUser();
  }
  public getActiveUser() : LoginInfoModel {
    this.userActive = JSON.parse(localStorage.getItem('currentUserCBM'));
    if(!this.userActive){
      this.logout();
    }
    return this.userActive;
  }
  public isLogged(){
    this.verifyUserLogged().subscribe( result => {
      console.log('LOGADO');
      this.userLogged();
    }, error => {
      this.requestIsLoggedFail();
    });
   
  }
  public getHeaderWithoutToken(): HeadersCustom {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return new HeadersCustom(headers);
  }
  public getHeaderWithToken(): HeadersCustom {
    let currentUserToken = this.getActiveUser();
    let headers = this.getHeaderWithoutToken();
    if (currentUserToken && currentUserToken.token && currentUserToken.token.length > 0) {
      headers.push( 'Authorization', 'Bearer ' + currentUserToken.token);
    }
    return headers;
  }
  public verifyUserLogged() : Observable<{ success : boolean }> {
    return this.http.get<any>(this.url+"/isLogged", this.getHeaderWithToken());
  }
  private userLogged(){
    this.getActiveUser();
  }
  private requestIsLoggedFail(){
    this.logout();
  }
}
export class HeadersCustom {
   constructor(public headers: HttpHeaders){
    headers = new HttpHeaders(); 
   }
   push(name: string, value: string){
    this.headers = this.headers.append(name, value);
   }
}