import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() colorBlue?: boolean = false;
  @Input() imgLink?: string;
  @Input() labelButton?: string;
  @Input() labelLink?: string;

  constructor(private router: Router) { }

  ngOnInit() {
  }
  labelLinkClick(){
    if(!this.labelLink){
      return;
    }
    this.router.navigate([this.labelLink]);
  }
}
