export  class Constants {
    public static urlsDownload : Array<LinkDownload> = [
       // { descricao: "Google Drive", link : "https://drive.google.com/open?id=1v5MJg6hTKXFWxnUgfiUPnGXLLK-WxHr6", principal : true},
        //{ descricao: "Link Direto", link : "https://drive.google.com/open?id=1v5MJg6hTKXFWxnUgfiUPnGXLLK-WxHr6", principal : false},
        //{ descricao: "Link Direto", link : "https://drive.google.com/open?id=1v5MJg6hTKXFWxnUgfiUPnGXLLK-WxHr6", principal : false},
    ];
    public static recaptcha_key = "6Ld-Q7UUAAAAAD_Xj3zpuiDlVS_FqVBO7jDjcMVs";
}
export interface LinkDownload {
    descricao: string;
    link: string;
    principal: boolean;
}
