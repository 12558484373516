import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-meu-painel',
  templateUrl: './meu-painel.component.html',
  styleUrls: ['./meu-painel.component.scss']
})
export class MeuPainelComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
