import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TicketModel } from './model/ticket.model';
import { ContactService } from 'src/app/service/contact.service';
import Swal from 'sweetalert2'
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/service/auth.service';
import { LoginInfoModel } from '../login/model/login-info.model';
import { Constants } from 'src/app/shared/constants';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-ajuda',
  templateUrl: './ajuda.component.html',
  styleUrls: ['./ajuda.component.scss']
})
export class AjudaComponent implements OnInit {

  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  
  ticket = new TicketModel();

  emailFormControl: FormControl;
  messageFormControl: FormControl;
  assuntoFormControl: FormControl;
  recaptchaFormControl: FormControl;
  siteKey = Constants.recaptcha_key;

  form: FormGroup;
  ticketAberto: boolean = false;

  userActive: LoginInfoModel;

  constructor(private contactService: ContactService, private router: Router, private route: ActivatedRoute, private authService: AuthService) { }

  ngOnInit() {
    this.bindFormControl();
    this.route.paramMap.subscribe(params => {
      this.getTicket(params.get("token"));
    });
  }
  handleReset(){}
  handleLoad(){}
  handleExpire(){}
  handleSuccess($event){
    this.ticket.tokenRobot =  $event;
  }
  getTicket(token: string) {
    if (!token) {
      return;
    }
    this.ticketAberto = true;
    this.form.disable();

    this.contactService.getTicket(token).subscribe(
      result => {
        this.getTicketSuccess(result.data);
      },
      error => {
        this.getTicketError(error);
      });
  }
  sendTicket() {
    if (!this.form.valid) {
      return;
    }

    this.form.disable();

    this.ticket.email = this.emailFormControl.value;
    this.ticket.message = this.messageFormControl.value;
    this.ticket.reason = this.assuntoFormControl.value;

    this.contactService.sendTicket(this.ticket).subscribe(
      result => {
        this.sendTicketSuccess(result.data);
      },
      error => {
        this.sendTicketError();
      });
  }
  retornaStatus(status: number): string {
    switch (status) {
      case 1:
        return "Em análise";
      case 2:
        return "Finalizado";
      default:
        return "Aberto";
    }
  }
  private bindFormControl() {
    this.emailFormControl = new FormControl({ value: (this.authService.logged ? this.authService.getActiveUser().email: this.ticket.email), disabled: this.ticket.id > 0 || this.authService.logged}, [
      Validators.required,
      Validators.email,
    ]);

    this.messageFormControl = new FormControl({ value: this.ticket.message, disabled: this.ticket.id > 0 }, [
      Validators.required
    ]);

    this.assuntoFormControl = new FormControl({ value: this.ticket.reason, disabled: this.ticket.id > 0 }, [
      Validators.required
    ]);

    this.recaptchaFormControl = new FormControl('', [
      Validators.required
    ]);

    this.form = new FormGroup({
      emailFormControl: this.emailFormControl,
      messageFormControl: this.messageFormControl,
      assuntoFormControl: this.assuntoFormControl,
      recaptchaFormControl: this.recaptchaFormControl
    });
  }
  private sendTicketSuccess(ticket: TicketModel) {
    this.form.enable();
    this.ticket = ticket;
    this.ticketAberto = true;

    Swal.fire("Sucesso!", "O seu Ticket foi criado! Acesse utilizando: <a href='http://www.cabalmix.com/ticket/" + ticket.token + "'>http://www.cabalmix.com/ticket/" + ticket.token + "</a> para acompanhar ", "success");
    this.bindFormControl();
  }
  private sendTicketError() {
    this.form.enable();
    Swal.fire("Ops...", "Ocorreu um erro ao criar seu ticket. Se o problema persistir, contate o administrador via grupo do telegram: https://t.me/+HpheQxFVT1pmYzNh", "error");
  }
  private getTicketSuccess(ticket: TicketModel) {
    this.form.enable();
    this.ticket = ticket;
    this.bindFormControl();
  }
  private getTicketError(error: HttpErrorResponse) {
    this.form.enable();
    this.captchaElem.resetCaptcha();
    if (error.status == 400) {
      Swal.fire("Ticket não encontrado.", "Caso queira abrir um novo, preencha os dados do formulário.", "warning");
    }
    else {
      Swal.fire("Ops...", "Ocorreu um erro ao consultar seu ticket. Se o problema persistir, contate o administrador via grupo do telegram: https://t.me/+HpheQxFVT1pmYzNh", "error");
    }

  }
}
