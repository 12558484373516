import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {


  title = 'cabal-mix-web';

  @HostListener('window:keydown', ['$event'])
  keyEvent($event: KeyboardEvent) {
    let charCode = String.fromCharCode($event.which).toLowerCase();
    if ((navigator.platform.match("Mac") ? $event.metaKey : $event.ctrlKey) && charCode === 's') {
      $event.preventDefault();
    }
    if (charCode === '{') {
      $event.preventDefault();
    }
  }
  /*  @HostListener('window:contextmenu', ['$event'])
   contextMenu($event: any) {
     $event.preventDefault();
   } */
}
