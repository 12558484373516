import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavigationComponent } from './navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { UiModule } from 'src/app/ui/ui.module';
import { BannerRankLevelComponent } from '../banner-rank-level/banner-rank-level.component';
import { LoginComponent } from 'src/app/site/login/login.component';
import { LoggedMenuComponent } from '../logged-menu/logged-menu.component';
import { MatDividerModule } from '@angular/material';

@NgModule({
  declarations: [NavigationComponent, BannerRankLevelComponent, LoginComponent, LoggedMenuComponent],
  imports: [
    CommonModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatDividerModule,
    UiModule
  ],
  exports: [NavigationComponent, BannerRankLevelComponent, LoginComponent]
})
export class NavigationModule { }
