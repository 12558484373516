export class RequestResetModel {
    email: string;
    userName: string;
    tokenRobot: string;
    tipo: TipoResetRequest;
    constructor(){
        this.tipo = TipoResetRequest.EMAIL;
    }
}
enum TipoResetRequest {
    EMAIL = 1,
    USER = 2
}