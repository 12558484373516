import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { UiModule } from './ui/ui.module';
import { MenuModule } from './menu/menu.module';
import { DirectiveModule } from './directive/directive.module';
import { SiteModule } from './site/site.module';
import { FormsModule } from '@angular/forms';
import {Ng2PageScrollModule} from 'ng2-page-scroll';
import { SharedModule } from './shared/shared.module';
import { ServiceModule } from './service/service.module';
import { LoginService } from './service/login.service';
import { AuthService } from './service/auth.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    MenuModule,
    SiteModule,
    DirectiveModule,
    UiModule,
    Ng2PageScrollModule,
    SharedModule,
    ServiceModule
  ],
  exports: [UiModule, FormsModule, SiteModule],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
