import { Injectable } from '@angular/core';
import { CharBasicInfoModel } from '../shared/model/character/char-basic-info-model';
import { GenericResponseArray } from '../shared/model/base/generic-response';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CharacterService {

  baseUrl = "api/Character";

  constructor(public http: HttpClient) { }
  public topPlayTime(count: number): Observable<GenericResponseArray<CharBasicInfoModel>> {
    var url = this.baseUrl +"/TopPlayTime" + "/"+count;
    return this.http.get<GenericResponseArray<CharBasicInfoModel>>(url);
}
  public topRank(count: number): Observable<GenericResponseArray<CharBasicInfoModel>> {
      var url = this.baseUrl +"/TopLevel" + "/"+count;
      return this.http.get<GenericResponseArray<CharBasicInfoModel>>(url);
  }
}
