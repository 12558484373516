import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CbmModule } from './cbm/cbm.module';
import { CarouselComponent } from './carousel/carousel.component';
import { BannerComponent } from './banner/banner.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CarouselComponent, BannerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    CbmModule
  ],
  exports: [ReactiveFormsModule, MaterialModule, BrowserAnimationsModule, CbmModule, CarouselComponent, BannerComponent]
})
export class UiModule { }
