import { Component, OnInit, Inject, ElementRef,Renderer2, ViewChild } from '@angular/core';
import { PageScrollInstance, PageScrollService } from 'ng2-page-scroll';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/service/account.service';
import { AccountInfoModel } from 'src/app/shared/model/account/account-info-model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private _elementRef : ElementRef, private route: ActivatedRoute, private pageScrollService: PageScrollService, @Inject(DOCUMENT) private document: any) { }

  imagens = new Array<string>();
  topPlayersOnline : Array<AccountInfoModel>;
  noticiasImg = [
    "https://http2.mlstatic.com/cabal-online-br-guerreiro-194-h15-arionell-arma-e-elmo-D_NQ_NP_645423-MLB26290483774_112017-F.jpg",
    "https://i.ytimg.com/vi/lUu1YMehMfM/maxresdefault.jpg",
    "https://mmosgame.com/downloads/r2.jpg",
    "https://mmosgame.com/downloads/cabal-02.jpg",
    "http://s.glbimg.com/po/tt/f/original/2012/11/12/cabal-online-180.jpg"
  ];
  ngOnInit() {
    this.buscarNotificas();
  }
  
  ngAfterViewInit() {
    this.verificarParametros();
}
  buscarNotificas(){
    this.imagens = this.noticiasImg;
  }
  private verificarParametros(){
    this.route.paramMap.subscribe(params => {
      this.targetLink(params.get("t"));
    })
  }
  private targetLink(target: string) {
    if(!target){
      return;
    }
    this._elementRef.nativeElement.querySelector(target).focus();
    //console.log("this._elementRef.nativeElement.querySelector(target): ", this._elementRef.nativeElement.querySelector(target));
   
   // let pageScrollInstance: PageScrollInstance = PageScrollInstance.newInstance({ document: this.document, scrollTarget: target, verticalScrolling: false });
    //this.pageScrollService.start(pageScrollInstance);
  }
  
}
