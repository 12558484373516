import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UiModule } from '../ui/ui.module';
import { NavigationModule } from './navigation/navigation.module';

@NgModule({
  declarations: [HeaderComponent, SidebarComponent],
  imports: [
    CommonModule,
    UiModule,
    NavigationModule
  ],
  exports: [HeaderComponent, NavigationModule]
})
export class MenuModule { }
