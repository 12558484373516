import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { ButtonNeonComponent } from './button-neon/button-neon.component';
import { RowDividerComponent } from './row-divider/row-divider.component';

@NgModule({
  declarations: [ButtonComponent, ButtonNeonComponent, RowDividerComponent],
  imports: [
    CommonModule
  ],
  exports: [ButtonComponent, ButtonNeonComponent, RowDividerComponent]
})
export class CbmModule { }
