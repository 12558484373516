import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginModel } from './model/login.model';
import { Router, ActivatedRoute } from '@angular/router';
import { MatMenuTrigger } from '@angular/material';
import { LoginService } from 'src/app/service/login.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @Input() loginTrigger?: MatMenuTrigger;
  @Input() redirectToHome?: boolean = true;

  loginModel: LoginModel;

  idJogadorFormControl: FormControl;
  senhaFormControl: FormControl;
  mensagemRetorno: string;
  returnUrl: string;

  form: FormGroup;

  constructor(private route: ActivatedRoute, private router: Router, private loginService: LoginService, private authService: AuthService) { }

  ngOnInit() {
    if(this.authService.logged && this.redirectToHome){
      this.redirectTo("home");
      return;
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.bindFormControl();
  }
  public redirectTo(link: string, target?: string) {
    if (target) {
      link += "/" + target;
    }
    this.router.navigate([link]).then(() => {
      if(this.loginTrigger != undefined){
        this.loginTrigger.closeMenu();
      }
    });
  }
  public login() {

    this.loginModel = new LoginModel();

    this.loginModel.userName = this.idJogadorFormControl.value;
    this.loginModel.password = this.senhaFormControl.value;
    this.mensagemRetorno = undefined;
    this.form.disable();
    this.loginService.login(this.loginModel).subscribe(
      result => {
        this.requestSuccess(result.data);
    }, 
      error => { 
        console.error(error), 
        this.requestFail(error);
    });
  }
  public logout(){
    this.authService.logout();
  }
  private requestSuccess(data){
    this.form.enable();
    this.authService.setLoginCache(data);
    if(this.loginTrigger != undefined){
      this.loginTrigger.closeMenu();
    }
    if(this.returnUrl){
      this.redirectTo(this.returnUrl);
    }
    else if(this.redirectToHome){
      this.redirectTo("home");
    }
  }
  private requestFail(data){
    this.form.enable();
    if(data.status == 401){
      this.mensagemRetorno =  "Login ou Senha inválidos.";
    }
    else if(data.status == 504){
      this.mensagemRetorno =  "Serviço indisponível no momento :(<br> Se o problema persistir, entre em contato com ADM do server.";
    }
    else {
      this.mensagemRetorno =  "Ocorreu um erro :/<br> Se o problema persistir, contate o ADM do sistema.";
    }
  } 
  private bindFormControl() {

    this.idJogadorFormControl = new FormControl('', [
      Validators.required,
      Validators.pattern("^[A-Za-z][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$")
    ]);

    this.senhaFormControl = new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]);

    this.form = new FormGroup({
      idJogadorFormControl: this.idJogadorFormControl,
      senhaFormControl: this.senhaFormControl,
    });
  }
}
