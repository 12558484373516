import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'button-neon',
  templateUrl: './button-neon.component.html',
  styleUrls: ['./button-neon.component.scss']
})
export class ButtonNeonComponent implements OnInit {

  @Input() texto: string;
  @Input() link?: string;
  
  texto1: string = "";
  texto2: string = "";
  texto3: string = "";

  constructor(private router: Router) { }

  ngOnInit() {
    if(this.texto.length >= 3){
      var metade = parseInt((this.texto.length / 2).toFixed(0));
      this.texto1 = this.texto.substr(0, metade);
      this.texto2 = this.texto.substr(metade, 1);
      this.texto3 = this.texto.substr(metade +1, this.texto.length - metade - 1);

    }
    else{
      this.texto1 = this.texto;
    }
  }
  redirectLink(){
    if(!this.link){
      return;
    }
    this.router.navigate([this.link]).catch(e => {
      var a = document.createElement('a');
      a.setAttribute('href', this.link);
      a.setAttribute('target', "_blank");
      a.click();
    });
  }
}
