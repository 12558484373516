import { Component, OnInit, Input } from '@angular/core';
import { Constants } from 'src/app/shared/constants';
import { AccountInfoModel } from 'src/app/shared/model/account/account-info-model';
import { AccountService } from 'src/app/service/account.service';
import { CharacterService } from 'src/app/service/character.service';
import { CharBasicInfoModel } from 'src/app/shared/model/character/char-basic-info-model';
import { HeaderCache } from './model/header-cache';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  topPlayersOnline: Array<CharBasicInfoModel>;
  topPlayersOnlineCache: HeaderCache<Array<CharBasicInfoModel>>;
  linkDownload = "";

  constructor(public accountService: AccountService, public characterService: CharacterService) { }

  ngOnInit() {
    if (Constants.urlsDownload.length > 0 && Constants.urlsDownload.find(x => x.principal)) {
      this.linkDownload = Constants.urlsDownload.find(x => x.principal).link;
    }
    // this.topPlayersOnlineCache = JSON.parse(localStorage.getItem("topPlayersOnlineCache"));

    // if(!this.topPlayersOnlineCache || this.varifyDateExpired(this.topPlayersOnlineCache.date)){
    //   this.topPlayersOnlineCache = new HeaderCache<Array<CharBasicInfoModel>>();
    //   this.buscarTopOnline();
    // }
    // else {
    //   this.topPlayersOnline = this.topPlayersOnlineCache.data;
    // }
  }
  buscarTopOnline() {
    this.characterService.topPlayTime(10).subscribe(
      result => {
        this.findPlayersSuccess(result.data);
      },
      error => {
        console.error(error),
          this.requestFail(error);
      });
  }
  
  public findPlayersSuccess(data) {
    this.topPlayersOnline = data;
    while(this.topPlayersOnline.length < 10){
      let player = new CharBasicInfoModel();
      player.name = " **************** ";
      this.topPlayersOnline.push(player);
    }
    this.topPlayersOnlineCache.data = this.topPlayersOnline;
    localStorage.setItem("topPlayersOnlineCache", JSON.stringify(this.topPlayersOnlineCache));
  }
  public requestFail(data) {
    //this.messageService.responseError(data);
  }
  public returnPlayTimeFormated(playTime: number): string {
    if(!playTime){
      return;
    }
    let hours = (playTime / 60);
    let minutes = (playTime % 60);
    return  '- '+(Math.round(hours) < 10 ? '0'+hours.toFixed(0): hours.toFixed(0))+':' + (Math.round(minutes) < 10 ? '0'+minutes.toFixed(0): minutes.toFixed(0)) + ' h';
  }
  private varifyDateExpired(date: Date) : boolean {
    var dateNow = new Date();
    var dateParam = new Date(date);
    return (dateParam.getTime() + (1000 * 60 * 1)) < dateNow.getTime();
  }
}
