export class TicketModel {
    id: number;
    Id: number;
    reason: string;
    email: string;
    message: string;
    answer : string;
    status : number;
    tokenRobot: string;
    token: string;
    Token: string;
}