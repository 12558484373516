import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountService } from './account.service';
import { CharacterService } from './character.service';
import { ContactService } from './contact.service';
import { IbgeService } from './ibge.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [AccountService, CharacterService, ContactService, IbgeService],
  exports:[]
})
export class ServiceModule { }
