import { Component, Inject, ViewChild, ChangeDetectorRef, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PageScrollService, PageScrollInstance } from 'ng2-page-scroll';
import { DOCUMENT } from '@angular/common';
import { MatMenuTrigger } from '@angular/material';
import { LoginService } from 'src/app/service/login.service';
import { AuthService } from 'src/app/service/auth.service';
import { LoginInfoModel } from 'src/app/site/login/model/login-info.model';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @ViewChild('loginTrigger', { static: false }) loginTrigger: MatMenuTrigger;
  @ViewChild('authTrigger', { static: false }) authTrigger: MatMenuTrigger;

  loginTriggerCarregado: MatMenuTrigger;

  userModel : LoginInfoModel;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      share()
    );

  constructor(public authService : AuthService, private cdRef: ChangeDetectorRef, private breakpointObserver: BreakpointObserver, private router: Router) { }
  
  ngOnInit(): void {
    this.authService.isLogged();
  }

  ngAfterViewChecked() {
    if (this.loginTrigger) {
      this.loginTriggerCarregado = this.loginTrigger;
    }
    this.cdRef.detectChanges();
  }

  redirectTo(link: string, target?: string) {
    if (target) {
      link += "/" + target;
    }
    this.router.navigate([link]);
  }
}
