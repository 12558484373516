import { Component, OnInit, Input } from '@angular/core';
import { CharacterService } from 'src/app/service/character.service';
import { HeaderCache } from '../header/model/header-cache';
import { CharBasicInfoModel } from 'src/app/shared/model/character/char-basic-info-model';

@Component({
  selector: 'app-banner-rank-level',
  templateUrl: './banner-rank-level.component.html',
  styleUrls: ['./banner-rank-level.component.scss']
})
export class BannerRankLevelComponent implements OnInit {

  topLevelOnlineCache: HeaderCache<Array<CharBasicInfoModel>>;
  topLevel: Array<CharBasicInfoModel>;

  constructor(public characterService: CharacterService) { }

  ngOnInit() {
  
  }

  load(){
    this.topLevelOnlineCache = JSON.parse(localStorage.getItem("topLevelOnlineCache"));
    if(!this.topLevelOnlineCache || this.varifyDateExpired(this.topLevelOnlineCache.date)){
      this.topLevelOnlineCache = new HeaderCache<Array<CharBasicInfoModel>>();
      this.buscarTopLevel();
    }
    else {
      this.topLevel = this.topLevelOnlineCache.data;
    }
  }
  buscarTopLevel() {
    this.characterService.topRank(10).subscribe(
      result => {
        this.findTopLevelSuccess(result.data);
      },
      error => {
        console.error(error)
      });
  }
  private findTopLevelSuccess(data: Array<CharBasicInfoModel>) {
    this.topLevel = data;
    while(this.topLevel.length < 10){
      let player = new CharBasicInfoModel();
      player.name = " **************** ";
      this.topLevel.push(player);
    }
    this.topLevelOnlineCache.data = this.topLevel;
    localStorage.setItem("topLevelOnlineCache", JSON.stringify(this.topLevelOnlineCache));
  }
  private varifyDateExpired(date: Date) : boolean {
    var dateNow = new Date();
    var dateParam = new Date(date);
    return (dateParam.getTime() + (1000 * 60 * 1)) < dateNow.getTime();
  }
}
