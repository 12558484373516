import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GenericResponseArray, GenericResponse, GenericResponseHttp } from '../shared/model/base/generic-response';
import { Observable } from 'rxjs';
import { AccountInfoModel } from '../shared/model/account/account-info-model';
import { CadastroModel } from '../site/cadastro/model/cadastro.model';
import { PasswordResetModel } from '../site/password-reset/model/password-reset.model';
import { AuthService } from './auth.service';
import { RequestResetModel } from '../site/request-reset/model/request-reset.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  baseUrl = "api/Account";

  constructor(public http: HttpClient, private authService: AuthService) { }

  public register(account: CadastroModel): Observable<GenericResponse<boolean>> {
      var url = this.baseUrl ;
      return this.http.post<GenericResponse<boolean>>(url, account, this.authService.getHeaderWithoutToken());
  }
  public passwordReset(reset: PasswordResetModel): Observable<GenericResponseHttp> {
      var url = this.baseUrl +"/ResetPassword" ;
      return this.http.post<GenericResponseHttp>(url, reset, this.authService.getHeaderWithToken());
  }
  public passwordResetByLink(reset: PasswordResetModel): Observable<GenericResponseHttp> {
      var url = this.baseUrl + "/ResetPasswordByLink" ;
      return this.http.post<GenericResponseHttp>(url, reset, this.authService.getHeaderWithoutToken());
  }
  public requestReset(reset: RequestResetModel): Observable<GenericResponseHttp> {
      var url = this.baseUrl +"/RequestReset" ;
      return this.http.post<GenericResponseHttp>(url, reset, this.authService.getHeaderWithoutToken());
  }
  public validateResetLink(link: string): Observable<GenericResponseHttp> {
      var url = this.baseUrl +"/ValidateResetLink/"+link ;
      return this.http.get<GenericResponseHttp>(url, this.authService.getHeaderWithoutToken());
  }
}
