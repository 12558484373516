import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { GenericResponse } from '../shared/model/base/generic-response';
import { AccountInfoModel } from '../shared/model/account/account-info-model';
import { LoginModel } from '../site/login/model/login.model';

const endPoint: string = "/api/Login";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient, private router: Router, public authService: AuthService) {}
  public login(userLogin: LoginModel) {
    return this.http.post<GenericResponse<AccountInfoModel>>(endPoint, userLogin, this.authService.getHeaderWithoutToken());
  }
  public redirectTo(link: string, target?: string) {
    if (target) {
      link += "/" + target;
    }
    this.router.navigate([link]);
  }
}
