import { Injectable } from '@angular/core';
import { TicketModel } from '../site/ajuda/model/ticket.model';
import { GenericResponseArray, GenericResponse } from '../shared/model/base/generic-response';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  baseUrl = "api/Contact";

  constructor(public http: HttpClient) { }

  public getTicket(token: string): Observable<GenericResponse<TicketModel>> {
    var url = this.baseUrl +"/"+token;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<GenericResponse<TicketModel>>(url, {headers: headers});
  }
  public sendTicket(ticket: TicketModel): Observable<GenericResponse<TicketModel>> {
    var url = this.baseUrl ;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<GenericResponse<TicketModel>>(url, ticket, {headers: headers});
  }
}
